<template lang="pug">
.stats.fixed
  img(ref='img' src='~@/assets/img-effect/pic-1.jpg' style='display: none')
canvas.canvas(ref='canvas')
</template>

<script>
window.__DEBUG__ = false;

import { onBeforeUnmount, onMounted, ref } from 'vue';
import createREGL from 'regl';
import createCamera from 'regl-camera';
import { createBallCommand } from '@/commands/image-effect/ball/index.js';
import { loadImg, resize } from './common';

export default {
  name: 'Ball',
  setup() {
    const canvas = ref(null);
    const img = ref(null);

    let regl;

    onMounted(async () => {
      resize(canvas.value);

      regl = createREGL({ canvas: canvas.value });
      const camera = createCamera(regl, {
        center: [0, 0, 0],
        distance: 0,
        damping: 0,
        theta: 0,
        phi: 0,
        fovy: Math.PI / 8,
      });

      const imgEl = await loadImg(img.value);
      const draw = createBallCommand(regl, imgEl);

      const ballStart = () => {
        regl.frame(({ time }) => {
          camera(() => {
            draw({ time });
          });
        });
      };

      ballStart();
    });

    onBeforeUnmount(() => regl?.destroy());

    return { canvas, img };
  },
};
</script>

<style lang="stylus" scoped>
@import './common.styl'
</style>
