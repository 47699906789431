import vert from './ball.vert';
import frag from './ball.frag';

const sin = (v) => Math.sin(v);
const cos = (v) => Math.cos(v);

/**
 * @param {Number} latitude 纬线分隔 纬线平行xz平面 指向东西
 * @param {Number} longitude 经线分隔 纬线平行yz平面 指向南北
 */
const getSphereCoordinate = (latitude = 30, longitude = 30) => {
  const radius = 1;
  let indices = [];
  const position = [];
  const uv = [];

  //纬线
  for (let i = 0; i <= latitude; i += 1) {
    const theta = (Math.PI * i) / latitude;
    //经线
    for (let j = 0; j <= longitude; j += 1) {
      const phi = (2 * Math.PI * j) / longitude;

      const x = radius * sin(theta) * cos(phi);
      const y = radius * cos(theta);
      const z = radius * sin(theta) * sin(phi);
      position.push([x, y, z]);

      const u = j / longitude;
      const v = i / latitude;
      uv.push([u, v]);

      if (i !== latitude && j !== longitude) {
        const topLeft = i * (longitude + 1) + j;
        const topRight = topLeft + 1;
        const bottomLeft = topLeft + longitude + 1;
        const bottomRight = bottomLeft + 1;

        indices.push(topLeft, bottomLeft, topRight, topRight, bottomLeft, bottomRight);
      }
    }
  }

  return { position, uv, indices };
};

/**
 * @param {ReturnType<createREGL>} regl
 */
export const createBallCommand = (regl, img) => {
  const { position, indices, uv } = getSphereCoordinate();

  return regl({
    vert,
    frag,
    attributes: {
      position,
      uv,
    },
    uniforms: {
      uTime: ({ time }) => time,
      uTexture: regl.texture({ data: img, flipY: true }),
    },
    elements: indices,
    depth: { enable: true },
    cull: { enable: true, face: 'back' },
    blend: {
      enable: false,
      func: {
        src: 'src alpha',
        dst: 'one minus src alpha',
      },
    },
  });
};
